import React, { useState } from "react";
import { NavLink } from "react-router-dom";

import menu from "./../../../assets/menu.svg";
import phone from "./../../../assets/phone.svg";
import phone2 from "./../../../assets/phone2.svg";
import location from "./../../../assets/location.svg";
import location2 from "./../../../assets/location2.svg";

const Header: React.FC<{}> = () => {
  // const [menuBar, setMenuBar] = useState("max-sm:hidden");

  // const handleMenuBar = () => {
  //   if (menuBar === "max-sm:flex") {
  //     setMenuBar("max-sm:hidden");
  //   } else {
  //     setMenuBar("max-sm:flex");
  //   }
  // };

  return (
    <header className="flex max-sm:flex-col max-sm:items-start">
      <div className="bg-white flex flex-row py-5 pl-12 max-sm:pl-8 gap-x-5 w-full">
        <div className=" text-white text-lg flex flex-row justify-between max-sm:w-fit">
          <div className="flex flex-col justify-center text-center cursor-pointer ">
            <p className="text-[22px] leading-6 text-[#2B7AF1] font-ruso">
              Повірка
            </p>
            <p className="bg-[#FF7D03] px-1 rounded-md text-sm font-ruso font-light">
              лічильників
            </p>
          </div>
          {/* <img
            className="w-7 sm:hidden cursor-pointer "
            src={menu}
            alt="menu"
            onClick={handleMenuBar}
          ></img> */}
        </div>
        <div className="flex max-sm:flex-col gap-x-4 w-full px-6">
          <ul
            // className={`h-full flex-wrap w-full max-sm:pt-4 flex items-center max-sm:items-start justify-between pl-0 gap-x-12 max-sm:flex-col gap-y-2 ${menuBar}`}
            className={`h-full flex-wrap w-full max-sm:pt-0 flex items-center max-sm:items-start justify-between pl-0 gap-x-12 max-sm:flex-col gap-y-2`}
          >
            <li className="max-sm:hidden">
              <NavLink
                to="/"
                className={({ isActive }) =>
                  isActive
                    ? "text-black font-bold border-b-2 border-black"
                    : "text-black"
                }
              >
                <span className="font-montserrat">Головна</span>
              </NavLink>
            </li>
            {/* <li>
              <NavLink
                to="/info"
                className={({ isActive }) =>
                  isActive
                    ? "text-black font-bold border-b-2 border-black"
                    : "text-black font-montserrat"
                }
              >
                <span className="font-montserrat">Вартість послуг</span>
              </NavLink>
            </li> */}
            {/* <li>
              <NavLink
                to="/partners"
                className={({ isActive }) =>
                  isActive
                    ? "text-black font-bold border-b-2 border-black"
                    : "text-black font-montserrat"
                }
              >
                <span className="font-montserrat">Наші партнери</span>
              </NavLink>
            </li> */}
            <div className="flex flex-col gap-y-2">
              <div className="flex flex-row max-sm:flex-col justify-between gap-x-8 gap-y-1">
                <div className="flex flex-row gap-x-4 font-semibold items-center">
                  <img
                    className="w-5 h-5"
                    src={location}
                    alt="локація Миколаїв"
                  />
                  <p className="text-sm">Миколаїв</p>
                </div>
                <div className="flex flex-row gap-x-4 font-semibold text-[#2B7AF1] items-center">
                  <img className="w-5 h-5" src={phone} alt="телефон Миколаїв" />
                  <a
                    href="tel:+380731680048"
                    className="text-inherit underline text-sm"
                  >
                    +380731680048
                  </a>
                </div>
              </div>
              <div className="flex flex-row max-sm:flex-col justify-between gap-x-8 gap-y-1">
                <div className="flex flex-row gap-x-4 font-semibold items-center">
                  <img
                    className="w-5 h-5"
                    src={location2}
                    alt="локація Південноукраїнськ"
                  />
                  <p className="text-sm">Південноукраїнськ</p>
                </div>
                <div className="flex flex-row gap-x-4 font-semibold text-[#2B7AF1] items-center">
                  <img
                    className="w-5 h-5"
                    src={phone2}
                    alt="телефон Південноукраїнськ"
                  />
                  <a
                    href="tel:+380933444843"
                    className="text-inherit underline text-sm"
                  >
                    +380933444843
                  </a>
                </div>
              </div>
            </div>
          </ul>
        </div>
      </div>
    </header>
  );
};

export default Header;
